import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/GenericLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Change Log`}</h1>
    <h2>{`2.23.6`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/0eded1fd63f081ba9aeab5b5946218e1c5b9b316"
        }}><inlineCode parentName="a">{`0eded1fd`}</inlineCode></a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/1bcb8b3093920fdd871f8a13b498a8eb99201200"
        }}><inlineCode parentName="a">{`1bcb8b30`}</inlineCode></a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/3419ff64a07056ae365956d03ec62f1e5e619f29"
        }}><inlineCode parentName="a">{`3419ff64`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:alert@7.0.0"
            }}>{`alert@7.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:anchor@5.0.2"
            }}>{`anchor@5.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:avatar@2.1.0"
            }}>{`avatar@2.1.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:base-radio-checkbox@6.0.2"
            }}>{`base-radio-checkbox@6.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:breadcrumb@4.0.2"
            }}>{`breadcrumb@4.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:button@7.0.0"
            }}>{`button@7.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:checkbox@6.0.2"
            }}>{`checkbox@6.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:combobox@7.0.0"
            }}>{`combobox@7.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:disclosure@5.0.2"
            }}>{`disclosure@5.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:form@6.0.2"
            }}>{`form@6.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:heading@5.0.2"
            }}>{`heading@5.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:help-text@6.0.2"
            }}>{`help-text@6.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:inline-control-group@6.0.2"
            }}>{`inline-control-group@6.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:input@3.0.4"
            }}>{`input@3.0.4`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:input-box@4.0.3"
            }}>{`input-box@4.0.3`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:label@6.0.2"
            }}>{`label@6.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:list@2.0.2"
            }}>{`list@2.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:menu@7.0.0"
            }}>{`menu@7.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:modal@7.0.0"
            }}>{`modal@7.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:paragraph@4.0.2"
            }}>{`paragraph@4.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:popover@6.0.0"
            }}>{`popover@6.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:radio-group@6.0.2"
            }}>{`radio-group@6.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:screen-reader-only@6.0.2"
            }}>{`screen-reader-only@6.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:select@5.0.2"
            }}>{`select@5.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:separator@2.0.3"
            }}>{`separator@2.0.3`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:spinner@7.0.2"
            }}>{`spinner@7.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:table@2.0.3"
            }}>{`table@2.0.3`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:tabs@2.0.3"
            }}>{`tabs@2.0.3`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:textarea@3.0.4"
            }}>{`textarea@3.0.4`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:toast@5.0.0"
            }}>{`toast@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:tooltip@4.0.2"
            }}>{`tooltip@4.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:aspect-ratio@4.0.3"
            }}>{`aspect-ratio@4.0.3`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:flex@2.0.3"
            }}>{`flex@2.0.3`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:grid@2.0.3"
            }}>{`grid@2.0.3`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:media-object@4.0.3"
            }}>{`media-object@4.0.3`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:stack@2.0.3"
            }}>{`stack@2.0.3`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@4.0.3"
            }}>{`box@4.0.3`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:combobox-primitive@0.2.4"
            }}>{`combobox-primitive@0.2.4`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:disclosure-primitive@0.3.4"
            }}>{`disclosure-primitive@0.3.4`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:menu-primitive@0.2.4"
            }}>{`menu-primitive@0.2.4`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:non-modal-dialog-primitive@0.3.4"
            }}>{`non-modal-dialog-primitive@0.3.4`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:sibling-box@3.0.3"
            }}>{`sibling-box@3.0.3`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:tabs-primitive@0.2.3"
            }}>{`tabs-primitive@0.2.3`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:text@4.0.2"
            }}>{`text@4.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:tooltip-primitive@0.2.4"
            }}>{`tooltip-primitive@0.2.4`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:icons@5.1.2"
            }}>{`icons@5.1.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@3.0.2"
            }}>{`style-props@3.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@5.0.2"
            }}>{`theme@5.0.2`}</a></li>
        </ul></li>
    </ul>
    <h2>{`2.23.5`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/169089cee859f40bf863e471745b867fd91c5837"
        }}><inlineCode parentName="a">{`169089ce`}</inlineCode></a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/8b5a8592848abba3975717c33ed9aed93f376087"
        }}><inlineCode parentName="a">{`8b5a8592`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:popover@5.0.0"
            }}>{`popover@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:icons@5.1.0"
            }}>{`icons@5.1.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:alert@6.0.0"
            }}>{`alert@6.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:anchor@5.0.0"
            }}>{`anchor@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:base-radio-checkbox@6.0.0"
            }}>{`base-radio-checkbox@6.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:button@6.0.0"
            }}>{`button@6.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:checkbox@6.0.0"
            }}>{`checkbox@6.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:combobox@6.0.0"
            }}>{`combobox@6.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:disclosure@5.0.0"
            }}>{`disclosure@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:form@6.0.0"
            }}>{`form@6.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:help-text@6.0.0"
            }}>{`help-text@6.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:inline-control-group@6.0.0"
            }}>{`inline-control-group@6.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:menu@6.0.0"
            }}>{`menu@6.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:modal@6.0.0"
            }}>{`modal@6.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:radio-group@6.0.0"
            }}>{`radio-group@6.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:screen-reader-only@6.0.0"
            }}>{`screen-reader-only@6.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:select@5.0.0"
            }}>{`select@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:spinner@7.0.0"
            }}>{`spinner@7.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:toast@4.0.0"
            }}>{`toast@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:tooltip@4.0.0"
            }}>{`tooltip@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:breadcrumb@4.0.0"
            }}>{`breadcrumb@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:label@6.0.0"
            }}>{`label@6.0.0`}</a></li>
        </ul></li>
    </ul>
    <h2>{`2.23.4`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/083b31d262b9350626bab9a926c95f91c99a4e43"
        }}><inlineCode parentName="a">{`083b31d2`}</inlineCode></a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/b1b4ac39ac70ec70bd9e150f2b8f94a123f1e1a6"
        }}><inlineCode parentName="a">{`b1b4ac39`}</inlineCode></a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/4c9ed5cac36ada218824d3e24bf45d4a03a12272"
        }}><inlineCode parentName="a">{`4c9ed5ca`}</inlineCode></a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/26c828d8681e0e671f28b5f2856cd1803f13953f"
        }}><inlineCode parentName="a">{`26c828d8`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:checkbox@5.0.0"
            }}>{`checkbox@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:label@5.0.0"
            }}>{`label@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:design-tokens@6.6.0"
            }}>{`design-tokens@6.6.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@5.0.0"
            }}>{`theme@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:form@5.0.0"
            }}>{`form@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:base-radio-checkbox@5.0.0"
            }}>{`base-radio-checkbox@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:combobox@5.0.0"
            }}>{`combobox@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:inline-control-group@5.0.0"
            }}>{`inline-control-group@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:radio-group@5.0.0"
            }}>{`radio-group@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:alert@5.0.0"
            }}>{`alert@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:anchor@4.0.0"
            }}>{`anchor@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:avatar@2.0.0"
            }}>{`avatar@2.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:breadcrumb@3.0.0"
            }}>{`breadcrumb@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:button@5.0.0"
            }}>{`button@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:disclosure@4.0.0"
            }}>{`disclosure@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:heading@5.0.0"
            }}>{`heading@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:help-text@5.0.0"
            }}>{`help-text@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:input@3.0.0"
            }}>{`input@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:input-box@4.0.0"
            }}>{`input-box@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:list@2.0.0"
            }}>{`list@2.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:menu@5.0.0"
            }}>{`menu@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:modal@5.0.0"
            }}>{`modal@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:paragraph@4.0.0"
            }}>{`paragraph@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:popover@4.0.0"
            }}>{`popover@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:screen-reader-only@5.0.0"
            }}>{`screen-reader-only@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:select@4.0.0"
            }}>{`select@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:separator@2.0.0"
            }}>{`separator@2.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:spinner@6.0.0"
            }}>{`spinner@6.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:table@2.0.0"
            }}>{`table@2.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:tabs@2.0.0"
            }}>{`tabs@2.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:textarea@3.0.0"
            }}>{`textarea@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:toast@3.0.0"
            }}>{`toast@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:tooltip@3.0.0"
            }}>{`tooltip@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:aspect-ratio@4.0.0"
            }}>{`aspect-ratio@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:flex@2.0.0"
            }}>{`flex@2.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:grid@2.0.0"
            }}>{`grid@2.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:media-object@4.0.0"
            }}>{`media-object@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:stack@2.0.0"
            }}>{`stack@2.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@4.0.0"
            }}>{`box@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:sibling-box@3.0.0"
            }}>{`sibling-box@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:text@4.0.0"
            }}>{`text@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:icons@5.0.0"
            }}>{`icons@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@3.0.0"
            }}>{`style-props@3.0.0`}</a></li>
        </ul></li>
    </ul>
    <h2>{`2.23.3`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/f1675586933bcce71a6b5c5fec7d939735763a73"
        }}><inlineCode parentName="a">{`f1675586`}</inlineCode></a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/f1675586933bcce71a6b5c5fec7d939735763a73"
        }}><inlineCode parentName="a">{`f1675586`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@4.3.0"
            }}>{`theme@4.3.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:combobox@4.0.0"
            }}>{`combobox@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:alert@4.0.0"
            }}>{`alert@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:anchor@3.0.0"
            }}>{`anchor@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:avatar@1.0.0"
            }}>{`avatar@1.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:base-radio-checkbox@4.0.0"
            }}>{`base-radio-checkbox@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:breadcrumb@2.0.0"
            }}>{`breadcrumb@2.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:button@4.0.0"
            }}>{`button@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:checkbox@4.0.0"
            }}>{`checkbox@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:disclosure@3.0.0"
            }}>{`disclosure@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:form@4.0.0"
            }}>{`form@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:heading@4.0.0"
            }}>{`heading@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:help-text@4.0.0"
            }}>{`help-text@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:inline-control-group@4.0.0"
            }}>{`inline-control-group@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:input@2.0.0"
            }}>{`input@2.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:input-box@3.0.0"
            }}>{`input-box@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:label@4.0.0"
            }}>{`label@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:list@1.0.0"
            }}>{`list@1.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:menu@4.0.0"
            }}>{`menu@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:modal@4.0.0"
            }}>{`modal@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:paragraph@3.0.0"
            }}>{`paragraph@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:popover@3.0.0"
            }}>{`popover@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:radio-group@4.0.0"
            }}>{`radio-group@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:screen-reader-only@4.0.0"
            }}>{`screen-reader-only@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:select@3.0.0"
            }}>{`select@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:separator@1.0.0"
            }}>{`separator@1.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:spinner@5.0.0"
            }}>{`spinner@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:table@1.0.0"
            }}>{`table@1.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:tabs@1.0.0"
            }}>{`tabs@1.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:textarea@2.0.0"
            }}>{`textarea@2.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:toast@2.0.0"
            }}>{`toast@2.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:tooltip@2.0.0"
            }}>{`tooltip@2.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:aspect-ratio@3.0.0"
            }}>{`aspect-ratio@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:flex@1.0.0"
            }}>{`flex@1.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:grid@1.0.0"
            }}>{`grid@1.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:media-object@3.0.0"
            }}>{`media-object@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:stack@1.0.0"
            }}>{`stack@1.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@3.0.0"
            }}>{`box@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:sibling-box@2.0.0"
            }}>{`sibling-box@2.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:text@3.0.0"
            }}>{`text@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:icons@4.0.0"
            }}>{`icons@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@2.0.0"
            }}>{`style-props@2.0.0`}</a></li>
        </ul></li>
    </ul>
    <h2>{`2.23.2`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/9617a70d51dccb3eb78148d708105fc162d152dd"
          }}><inlineCode parentName="a">{`9617a70d`}</inlineCode></a>{` `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/pull/1139"
          }}>{`#1139`}</a>{` Thanks `}<a parentName="p" {...{
            "href": "https://github.com/richbachman"
          }}>{`@richbachman`}</a>{`! - Removed required from the `}<inlineCode parentName="p">{`value`}</inlineCode>{` prop description in the Input page anatomy table.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Updated dependencies [`}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/1c9683c63e1db1333f26b5625aa21dea16e8ff69"
          }}><inlineCode parentName="a">{`1c9683c6`}</inlineCode></a>{`]:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:input@1.2.2"
            }}>{`input@1.2.2`}</a></li>
        </ul>
      </li>
    </ul>
    <h2>{`2.23.1`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/4114dac24d6b89f10aeeaeda2220825b9e146169"
        }}><inlineCode parentName="a">{`4114dac2`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:icons@3.13.0"
            }}>{`icons@3.13.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:alert@3.0.0"
            }}>{`alert@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:anchor@2.0.0"
            }}>{`anchor@2.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:base-radio-checkbox@3.0.0"
            }}>{`base-radio-checkbox@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:button@3.0.0"
            }}>{`button@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:checkbox@3.0.0"
            }}>{`checkbox@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:combobox@3.0.0"
            }}>{`combobox@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:disclosure@2.0.0"
            }}>{`disclosure@2.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:form@3.0.0"
            }}>{`form@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:help-text@3.0.0"
            }}>{`help-text@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:inline-control-group@3.0.0"
            }}>{`inline-control-group@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:menu@3.0.0"
            }}>{`menu@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:modal@3.0.0"
            }}>{`modal@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:popover@2.0.0"
            }}>{`popover@2.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:radio-group@3.0.0"
            }}>{`radio-group@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:screen-reader-only@3.0.0"
            }}>{`screen-reader-only@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:select@2.0.0"
            }}>{`select@2.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:spinner@4.0.0"
            }}>{`spinner@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:toast@1.0.0"
            }}>{`toast@1.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:tooltip@1.0.0"
            }}>{`tooltip@1.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:breadcrumb@1.0.0"
            }}>{`breadcrumb@1.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:label@3.0.0"
            }}>{`label@3.0.0`}</a></li>
        </ul></li>
    </ul>
    <h2>{`2.23.0`}</h2>
    <h3>{`Minor Changes`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/5eef071cd7e293e111ee21f350e774651881f5b8"
        }}><inlineCode parentName="a">{`5eef071c`}</inlineCode></a>{` `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/pull/1063"
        }}>{`#1063`}</a>{` Thanks `}<a parentName="li" {...{
          "href": "https://github.com/SiTaggart"
        }}>{`@SiTaggart`}</a>{`! - Update getting started with engineering quickstart guide`}</li>
    </ul>
    <h2>{`2.22.2`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/bf2859d0b9d6611a3833e5ffa9f06d298783b2e1"
        }}><inlineCode parentName="a">{`bf2859d0`}</inlineCode></a>{` `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/pull/1116"
        }}>{`#1116`}</a>{` Thanks `}<a parentName="li" {...{
          "href": "https://github.com/SiTaggart"
        }}>{`@SiTaggart`}</a>{`! - Updated the homepage hero call out to advertise the new manager job role`}</li>
    </ul>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/b82650716256de22aeff36e00d46577f71d1b3fd"
          }}><inlineCode parentName="a">{`b8265071`}</inlineCode></a>{` `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/pull/1113"
          }}>{`#1113`}</a>{` Thanks `}<a parentName="p" {...{
            "href": "https://github.com/richbachman"
          }}>{`@richbachman`}</a>{`! - Updated anatomy table on Disclosure doc page to reflect style changes.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Updated dependencies [`}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/b82650716256de22aeff36e00d46577f71d1b3fd"
          }}><inlineCode parentName="a">{`b8265071`}</inlineCode></a>{`]:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:disclosure@1.0.0"
            }}>{`disclosure@1.0.0`}</a></li>
        </ul>
      </li>
    </ul>
    <p>{`All notable changes to this project will be documented in this file.
See `}<a parentName="p" {...{
        "href": "https://conventionalcommits.org"
      }}>{`Conventional Commits`}</a>{` for commit guidelines.`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.22.0...@twilio-paste/website@2.22.1"
      }}>{`2.22.1`}</a>{` (2021-01-25)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` minor typing updates (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/592c015bec2ba7bbc4df7a9bf8fc8aacc59bcba7"
        }}>{`592c015`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.21.5...@twilio-paste/website@2.22.0"
      }}>{`2.22.0`}</a>{` (2021-01-21)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`ESBuild for builds (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/1084"
        }}>{`#1084`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/053646011508be10477d5b732269cdb0419235d7"
        }}>{`0536460`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.21.4...@twilio-paste/website@2.21.5"
      }}>{`2.21.5`}</a>{` (2021-01-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.21.3...@twilio-paste/website@2.21.4"
      }}>{`2.21.4`}</a>{` (2021-01-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.21.2...@twilio-paste/website@2.21.3"
      }}>{`2.21.3`}</a>{` (2021-01-14)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` fix contact menu overlap (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/dc33102c080316fe80020d8b1f233c0af6d7f272"
        }}>{`dc33102`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.21.1...@twilio-paste/website@2.21.2"
      }}>{`2.21.2`}</a>{` (2021-01-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.21.0...@twilio-paste/website@2.21.1"
      }}>{`2.21.1`}</a>{` (2021-01-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.20.7...@twilio-paste/website@2.21.0"
      }}>{`2.21.0`}</a>{` (2021-01-13)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` change slack to discussions on contribution guidelines (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/1080"
        }}>{`#1080`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/d6a4d1be325f8cb35a459944560814d1ca0afb83"
        }}>{`d6a4d1b`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.20.6...@twilio-paste/website@2.20.7"
      }}>{`2.20.7`}</a>{` (2021-01-13)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` add periods to help text, fix small typo on input page (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/1057"
        }}>{`#1057`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/be0c5f1215ae6fd5ec4f2aab915721ec1a0bfd2d"
        }}>{`be0c5f1`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.20.5...@twilio-paste/website@2.20.6"
      }}>{`2.20.6`}</a>{` (2021-01-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.20.4...@twilio-paste/website@2.20.5"
      }}>{`2.20.5`}</a>{` (2021-01-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.20.3...@twilio-paste/website@2.20.4"
      }}>{`2.20.4`}</a>{` (2021-01-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.20.2...@twilio-paste/website@2.20.3"
      }}>{`2.20.3`}</a>{` (2021-01-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.20.1...@twilio-paste/website@2.20.2"
      }}>{`2.20.2`}</a>{` (2021-01-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.20.0...@twilio-paste/website@2.20.1"
      }}>{`2.20.1`}</a>{` (2021-01-06)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.19.2...@twilio-paste/website@2.20.0"
      }}>{`2.20.0`}</a>{` (2021-01-04)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` update copy around pattern contribution (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/1056"
        }}>{`#1056`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/83e8a6e7ad48e84ab0314c092db9fd3eadc37d3a"
        }}>{`83e8a6e`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.19.1...@twilio-paste/website@2.19.2"
      }}>{`2.19.2`}</a>{` (2020-12-18)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.19.0...@twilio-paste/website@2.19.1"
      }}>{`2.19.1`}</a>{` (2020-12-18)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.18.1...@twilio-paste/website@2.19.0"
      }}>{`2.19.0`}</a>{` (2020-12-17)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` update font guidelines for default theme (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/43f4477205673927cb9866eb004cb077eb78df7b"
        }}>{`43f4477`}</a>{`)`}</li>
    </ul>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` switch to loading font.css (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/67ec025d477c4e17c23869b87ff70f8c708473fb"
        }}>{`67ec025`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.18.0...@twilio-paste/website@2.18.1"
      }}>{`2.18.1`}</a>{` (2020-12-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.17.4...@twilio-paste/website@2.18.0"
      }}>{`2.18.0`}</a>{` (2020-12-17)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` add content and search skip links (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/1048"
        }}>{`#1048`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/1f528597746bf28dce06e3f1484310b0ad4217bd"
        }}>{`1f52859`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.17.3...@twilio-paste/website@2.17.4"
      }}>{`2.17.4`}</a>{` (2020-12-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.17.2...@twilio-paste/website@2.17.3"
      }}>{`2.17.3`}</a>{` (2020-12-16)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.17.1...@twilio-paste/website@2.17.2"
      }}>{`2.17.2`}</a>{` (2020-12-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.17.0...@twilio-paste/website@2.17.1"
      }}>{`2.17.1`}</a>{` (2020-12-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.16.5...@twilio-paste/website@2.17.0"
      }}>{`2.17.0`}</a>{` (2020-12-11)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` typing issue with window size hook (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/f0fb531cac2f5fcfca18e7e94b4562f05cd67435"
        }}>{`f0fb531`}</a>{`)`}</li>
    </ul>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`theme-designer:`}</strong>{` add the theme designer to Paste (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/b77440cbb5d681532a5b6833a1f7b160909508e5"
        }}>{`b77440c`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.16.4...@twilio-paste/website@2.16.5"
      }}>{`2.16.5`}</a>{` (2020-12-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.16.3...@twilio-paste/website@2.16.4"
      }}>{`2.16.4`}</a>{` (2020-12-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.16.2...@twilio-paste/website@2.16.3"
      }}>{`2.16.3`}</a>{` (2020-12-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.16.1...@twilio-paste/website@2.16.2"
      }}>{`2.16.2`}</a>{` (2020-12-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.16.0...@twilio-paste/website@2.16.1"
      }}>{`2.16.1`}</a>{` (2020-12-03)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.15.0...@twilio-paste/website@2.16.0"
      }}>{`2.16.0`}</a>{` (2020-12-03)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` add SuperFriendly credit for icons on about page (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/992"
        }}>{`#992`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/b99bdb2a31ca87f0a1daff9d3d9cdf47bbe7db04"
        }}>{`b99bdb2`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.14.19...@twilio-paste/website@2.15.0"
      }}>{`2.15.0`}</a>{` (2020-12-02)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` new homepage and made website responsive (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/c8a146d716f1973e8a23a66898b7f549f9069531"
        }}>{`c8a146d`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.14.18...@twilio-paste/website@2.14.19"
      }}>{`2.14.19`}</a>{` (2020-11-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.14.17...@twilio-paste/website@2.14.18"
      }}>{`2.14.18`}</a>{` (2020-11-19)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.14.16...@twilio-paste/website@2.14.17"
      }}>{`2.14.17`}</a>{` (2020-11-18)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.14.15...@twilio-paste/website@2.14.16"
      }}>{`2.14.16`}</a>{` (2020-11-16)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.14.14...@twilio-paste/website@2.14.15"
      }}>{`2.14.15`}</a>{` (2020-11-13)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`update broken Abstract links in design guide (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/936"
        }}>{`#936`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/8a8d1429496fec441007689ec78d185ee8276cb2"
        }}>{`8a8d142`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.14.13...@twilio-paste/website@2.14.14"
      }}>{`2.14.14`}</a>{` (2020-11-13)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` typo on non-modal dialog primitive docs (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/932"
        }}>{`#932`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/00042bb484ba1bd01964a608aaaac59f6dfbbba0"
        }}>{`00042bb`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.14.12...@twilio-paste/website@2.14.13"
      }}>{`2.14.13`}</a>{` (2020-11-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.14.11...@twilio-paste/website@2.14.12"
      }}>{`2.14.12`}</a>{` (2020-11-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.14.10...@twilio-paste/website@2.14.11"
      }}>{`2.14.11`}</a>{` (2020-11-10)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.14.9...@twilio-paste/website@2.14.10"
      }}>{`2.14.10`}</a>{` (2020-11-10)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.14.8...@twilio-paste/website@2.14.9"
      }}>{`2.14.9`}</a>{` (2020-11-10)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.14.7...@twilio-paste/website@2.14.8"
      }}>{`2.14.8`}</a>{` (2020-11-10)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.14.6...@twilio-paste/website@2.14.7"
      }}>{`2.14.7`}</a>{` (2020-11-10)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.14.5...@twilio-paste/website@2.14.6"
      }}>{`2.14.6`}</a>{` (2020-11-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.14.4...@twilio-paste/website@2.14.5"
      }}>{`2.14.5`}</a>{` (2020-11-06)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.14.3...@twilio-paste/website@2.14.4"
      }}>{`2.14.4`}</a>{` (2020-11-05)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.14.2...@twilio-paste/website@2.14.3"
      }}>{`2.14.3`}</a>{` (2020-11-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.14.1...@twilio-paste/website@2.14.2"
      }}>{`2.14.2`}</a>{` (2020-11-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.14.0...@twilio-paste/website@2.14.1"
      }}>{`2.14.1`}</a>{` (2020-10-30)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.13.17...@twilio-paste/website@2.14.0"
      }}>{`2.14.0`}</a>{` (2020-10-30)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` Patterns PoC (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/812"
        }}>{`#812`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/a66136efcf9b656e9e205a450f2367f9a79049d7"
        }}>{`a66136e`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.13.16...@twilio-paste/website@2.13.17"
      }}>{`2.13.17`}</a>{` (2020-10-29)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.13.15...@twilio-paste/website@2.13.16"
      }}>{`2.13.16`}</a>{` (2020-10-28)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.13.14...@twilio-paste/website@2.13.15"
      }}>{`2.13.15`}</a>{` (2020-10-28)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.13.13...@twilio-paste/website@2.13.14"
      }}>{`2.13.14`}</a>{` (2020-10-27)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.13.12...@twilio-paste/website@2.13.13"
      }}>{`2.13.13`}</a>{` (2020-10-27)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.13.11...@twilio-paste/website@2.13.12"
      }}>{`2.13.12`}</a>{` (2020-10-26)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.13.10...@twilio-paste/website@2.13.11"
      }}>{`2.13.11`}</a>{` (2020-10-26)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.13.9...@twilio-paste/website@2.13.10"
      }}>{`2.13.10`}</a>{` (2020-10-23)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.13.8...@twilio-paste/website@2.13.9"
      }}>{`2.13.9`}</a>{` (2020-10-23)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.13.7...@twilio-paste/website@2.13.8"
      }}>{`2.13.8`}</a>{` (2020-10-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.13.6...@twilio-paste/website@2.13.7"
      }}>{`2.13.7`}</a>{` (2020-10-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.13.5...@twilio-paste/website@2.13.6"
      }}>{`2.13.6`}</a>{` (2020-10-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.13.4...@twilio-paste/website@2.13.5"
      }}>{`2.13.5`}</a>{` (2020-10-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.13.3...@twilio-paste/website@2.13.4"
      }}>{`2.13.4`}</a>{` (2020-10-21)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` generate groupedList as initialState to prevent mismatch (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/5153320723c2e6ee771676567d6007c6a11dd329"
        }}>{`5153320`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.13.2...@twilio-paste/website@2.13.3"
      }}>{`2.13.3`}</a>{` (2020-10-19)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.13.1...@twilio-paste/website@2.13.2"
      }}>{`2.13.2`}</a>{` (2020-10-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.13.0...@twilio-paste/website@2.13.1"
      }}>{`2.13.1`}</a>{` (2020-10-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.12.6...@twilio-paste/website@2.13.0"
      }}>{`2.13.0`}</a>{` (2020-10-15)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` switch the theme switcher to default to default (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/852"
        }}>{`#852`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/68d506395aa545ac6b25adc800111fea304bdcda"
        }}>{`68d5063`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.12.5...@twilio-paste/website@2.12.6"
      }}>{`2.12.6`}</a>{` (2020-10-14)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` correctly load or monospace font (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/67af512ee53f5b62d44486cccbbafc11744446c3"
        }}>{`67af512`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.12.4...@twilio-paste/website@2.12.5"
      }}>{`2.12.5`}</a>{` (2020-10-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.12.3...@twilio-paste/website@2.12.4"
      }}>{`2.12.4`}</a>{` (2020-10-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.12.2...@twilio-paste/website@2.12.3"
      }}>{`2.12.3`}</a>{` (2020-10-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.12.1...@twilio-paste/website@2.12.2"
      }}>{`2.12.2`}</a>{` (2020-10-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.12.0...@twilio-paste/website@2.12.1"
      }}>{`2.12.1`}</a>{` (2020-10-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.11.0...@twilio-paste/website@2.12.0"
      }}>{`2.12.0`}</a>{` (2020-10-07)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` full width and inverse header (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/b7a02d57560adac66c0ab80451e8c7b5b48c430e"
        }}>{`b7a02d5`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.10.6...@twilio-paste/website@2.11.0"
      }}>{`2.11.0`}</a>{` (2020-10-07)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` add alpha Table doc page (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/515393621faedd5b80de5ae1df8e5370c8776104"
        }}>{`5153936`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.10.5...@twilio-paste/website@2.10.6"
      }}>{`2.10.6`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.10.4...@twilio-paste/website@2.10.5"
      }}>{`2.10.5`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.10.3...@twilio-paste/website@2.10.4"
      }}>{`2.10.4`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.10.2...@twilio-paste/website@2.10.3"
      }}>{`2.10.3`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.10.1...@twilio-paste/website@2.10.2"
      }}>{`2.10.2`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.10.0...@twilio-paste/website@2.10.1"
      }}>{`2.10.1`}</a>{` (2020-10-01)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` dead link on icons page (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/817"
        }}>{`#817`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/e0948df1da49121e8866d707c43680cfa9d9345c"
        }}>{`e0948df`}</a>{`), closes `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/816"
        }}>{`#816`}</a></li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.9.1...@twilio-paste/website@2.10.0"
      }}>{`2.10.0`}</a>{` (2020-09-28)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` add design guidelines (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/779"
        }}>{`#779`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/31d9439c1a18d3c6be3db2fd5d04d1578a1b1890"
        }}>{`31d9439`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.9.0...@twilio-paste/website@2.9.1"
      }}>{`2.9.1`}</a>{` (2020-09-28)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.8.2...@twilio-paste/website@2.9.0"
      }}>{`2.9.0`}</a>{` (2020-09-25)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` update whats new (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/796"
        }}>{`#796`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/a9dfaf24b75c54b350dbf74e60f0aa90c633632b"
        }}>{`a9dfaf2`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.8.1...@twilio-paste/website@2.8.2"
      }}>{`2.8.2`}</a>{` (2020-09-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.8.0...@twilio-paste/website@2.8.1"
      }}>{`2.8.1`}</a>{` (2020-09-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.7.7...@twilio-paste/website@2.8.0"
      }}>{`2.8.0`}</a>{` (2020-09-23)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` getting started FAQs (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/777"
        }}>{`#777`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/1cafd0ba37ad6340c20377faffb10c7e4a140240"
        }}>{`1cafd0b`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.7.6...@twilio-paste/website@2.7.7"
      }}>{`2.7.7`}</a>{` (2020-09-23)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.7.5...@twilio-paste/website@2.7.6"
      }}>{`2.7.6`}</a>{` (2020-09-23)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` adjust callout to use stack (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/778"
        }}>{`#778`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/32b81d58573516747f3c94b30ad41c554cbe96ca"
        }}>{`32b81d5`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.7.4...@twilio-paste/website@2.7.5"
      }}>{`2.7.5`}</a>{` (2020-09-23)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.7.3...@twilio-paste/website@2.7.4"
      }}>{`2.7.4`}</a>{` (2020-09-23)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.7.2...@twilio-paste/website@2.7.3"
      }}>{`2.7.3`}</a>{` (2020-09-23)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.7.1...@twilio-paste/website@2.7.2"
      }}>{`2.7.2`}</a>{` (2020-09-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.7.0...@twilio-paste/website@2.7.1"
      }}>{`2.7.1`}</a>{` (2020-09-21)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.6.2...@twilio-paste/website@2.7.0"
      }}>{`2.7.0`}</a>{` (2020-09-21)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` automatically show external link icon in mdx (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/9c9a73de9f58f86cba010f319ff602b454f91d4d"
        }}>{`9c9a73d`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.6.1...@twilio-paste/website@2.6.2"
      }}>{`2.6.2`}</a>{` (2020-09-21)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`text:`}</strong>{` typo in propos (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/d33d8dbec50210b0480ee0b1ca45dc0dcf029b6a"
        }}>{`d33d8db`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.6.0...@twilio-paste/website@2.6.1"
      }}>{`2.6.1`}</a>{` (2020-09-18)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` update instructions on creating icon SVGs (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/738"
        }}>{`#738`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/d850677d2fdf67d80edd9da20709657492727546"
        }}>{`d850677`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.5.7...@twilio-paste/website@2.6.0"
      }}>{`2.6.0`}</a>{` (2020-09-18)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`docs:`}</strong>{` remove "here" from homepage CTA (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/745"
        }}>{`#745`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/04279bbb07415191962d8d2fdcb13816603fb214"
        }}>{`04279bb`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.5.6...@twilio-paste/website@2.5.7"
      }}>{`2.5.7`}</a>{` (2020-09-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.5.5...@twilio-paste/website@2.5.6"
      }}>{`2.5.6`}</a>{` (2020-09-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.5.4...@twilio-paste/website@2.5.5"
      }}>{`2.5.5`}</a>{` (2020-09-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.5.3...@twilio-paste/website@2.5.4"
      }}>{`2.5.4`}</a>{` (2020-09-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.5.2...@twilio-paste/website@2.5.3"
      }}>{`2.5.3`}</a>{` (2020-09-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.5.1...@twilio-paste/website@2.5.2"
      }}>{`2.5.2`}</a>{` (2020-09-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.5.0...@twilio-paste/website@2.5.1"
      }}>{`2.5.1`}</a>{` (2020-09-10)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.4.2...@twilio-paste/website@2.5.0"
      }}>{`2.5.0`}</a>{` (2020-09-09)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` setup Algolia DocSearch (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/703"
        }}>{`#703`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/49ef97a1c4304a44ce3268dae13f0c08cf2d6f2a"
        }}>{`49ef97a`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.4.1...@twilio-paste/website@2.4.2"
      }}>{`2.4.2`}</a>{` (2020-09-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.4.0...@twilio-paste/website@2.4.1"
      }}>{`2.4.1`}</a>{` (2020-09-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.3.8...@twilio-paste/website@2.4.0"
      }}>{`2.4.0`}</a>{` (2020-09-09)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` add illlustration to the homepage (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/c70996832a0801e5d5b09be4de3b06da873301e3"
        }}>{`c709968`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.3.7...@twilio-paste/website@2.3.8"
      }}>{`2.3.8`}</a>{` (2020-09-09)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` update components card to use heading20 variant (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/735"
        }}>{`#735`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/c445ca69d5008bda003ea3a08664c735409edfb4"
        }}>{`c445ca6`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.3.6...@twilio-paste/website@2.3.7"
      }}>{`2.3.7`}</a>{` (2020-09-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.3.5...@twilio-paste/website@2.3.6"
      }}>{`2.3.6`}</a>{` (2020-09-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.3.4...@twilio-paste/website@2.3.5"
      }}>{`2.3.5`}</a>{` (2020-09-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.3.3...@twilio-paste/website@2.3.4"
      }}>{`2.3.4`}</a>{` (2020-09-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.3.2...@twilio-paste/website@2.3.3"
      }}>{`2.3.3`}</a>{` (2020-09-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.3.1...@twilio-paste/website@2.3.2"
      }}>{`2.3.2`}</a>{` (2020-09-03)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.3.0...@twilio-paste/website@2.3.1"
      }}>{`2.3.1`}</a>{` (2020-09-03)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.2.28...@twilio-paste/website@2.3.0"
      }}>{`2.3.0`}</a>{` (2020-09-03)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` sticky header and sidebar issues (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/2d478330399af42dc65888dee3377cc8a586d615"
        }}>{`2d47833`}</a>{`)`}</li>
    </ul>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` icon list page (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/0c24c3cf63324e71998bfe1a023c4861f9ccd305"
        }}>{`0c24c3c`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.2.27...@twilio-paste/website@2.2.28"
      }}>{`2.2.28`}</a>{` (2020-09-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.2.26...@twilio-paste/website@2.2.27"
      }}>{`2.2.27`}</a>{` (2020-09-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.2.25...@twilio-paste/website@2.2.26"
      }}>{`2.2.26`}</a>{` (2020-09-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.2.24...@twilio-paste/website@2.2.25"
      }}>{`2.2.25`}</a>{` (2020-09-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.2.23...@twilio-paste/website@2.2.24"
      }}>{`2.2.24`}</a>{` (2020-09-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.2.22...@twilio-paste/website@2.2.23"
      }}>{`2.2.23`}</a>{` (2020-09-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.2.21...@twilio-paste/website@2.2.22"
      }}>{`2.2.22`}</a>{` (2020-09-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.2.20...@twilio-paste/website@2.2.21"
      }}>{`2.2.21`}</a>{` (2020-08-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.2.19...@twilio-paste/website@2.2.20"
      }}>{`2.2.20`}</a>{` (2020-08-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.2.18...@twilio-paste/website@2.2.19"
      }}>{`2.2.19`}</a>{` (2020-08-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.2.17...@twilio-paste/website@2.2.18"
      }}>{`2.2.18`}</a>{` (2020-08-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.2.16...@twilio-paste/website@2.2.17"
      }}>{`2.2.17`}</a>{` (2020-08-27)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.2.15...@twilio-paste/website@2.2.16"
      }}>{`2.2.16`}</a>{` (2020-08-26)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.2.14...@twilio-paste/website@2.2.15"
      }}>{`2.2.15`}</a>{` (2020-08-26)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` fix topbar zIndex (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/686"
        }}>{`#686`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/985b4fb97fe86bfb1a1b573a2f9269e7edf34c94"
        }}>{`985b4fb`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.2.13...@twilio-paste/website@2.2.14"
      }}>{`2.2.14`}</a>{` (2020-08-26)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.2.12...@twilio-paste/website@2.2.13"
      }}>{`2.2.13`}</a>{` (2020-08-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.2.11...@twilio-paste/website@2.2.12"
      }}>{`2.2.12`}</a>{` (2020-08-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.2.10...@twilio-paste/website@2.2.11"
      }}>{`2.2.11`}</a>{` (2020-08-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.2.9...@twilio-paste/website@2.2.10"
      }}>{`2.2.10`}</a>{` (2020-08-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.2.8...@twilio-paste/website@2.2.9"
      }}>{`2.2.9`}</a>{` (2020-08-24)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.2.7...@twilio-paste/website@2.2.8"
      }}>{`2.2.8`}</a>{` (2020-08-24)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.2.6...@twilio-paste/website@2.2.7"
      }}>{`2.2.7`}</a>{` (2020-08-24)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.2.5...@twilio-paste/website@2.2.6"
      }}>{`2.2.6`}</a>{` (2020-08-24)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.2.4...@twilio-paste/website@2.2.5"
      }}>{`2.2.5`}</a>{` (2020-08-21)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.2.3...@twilio-paste/website@2.2.4"
      }}>{`2.2.4`}</a>{` (2020-08-19)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` typo fixes how to work with us (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/667"
        }}>{`#667`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/22a1526926ffe88c1013c227d53c3c543753afc1"
        }}>{`22a1526`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.2.2...@twilio-paste/website@2.2.3"
      }}>{`2.2.3`}</a>{` (2020-08-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.2.1...@twilio-paste/website@2.2.2"
      }}>{`2.2.2`}</a>{` (2020-08-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.2.0...@twilio-paste/website@2.2.1"
      }}>{`2.2.1`}</a>{` (2020-08-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.1.1...@twilio-paste/website@2.2.0"
      }}>{`2.2.0`}</a>{` (2020-08-12)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`button:`}</strong>{` add inverse variant (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/4a6f24139fa43edfd048894c887adea8e821d401"
        }}>{`4a6f241`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.1.0...@twilio-paste/website@2.1.1"
      }}>{`2.1.1`}</a>{` (2020-08-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.0.5...@twilio-paste/website@2.1.0"
      }}>{`2.1.0`}</a>{` (2020-08-11)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`avatar:`}</strong>{` change background and font weight for initials (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/bf97215ee0dbef97b6acc0238e66b1233c2e6a7d"
        }}>{`bf97215`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.0.4...@twilio-paste/website@2.0.5"
      }}>{`2.0.5`}</a>{` (2020-08-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.0.3...@twilio-paste/website@2.0.4"
      }}>{`2.0.4`}</a>{` (2020-08-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.0.2...@twilio-paste/website@2.0.3"
      }}>{`2.0.3`}</a>{` (2020-08-06)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.0.1...@twilio-paste/website@2.0.2"
      }}>{`2.0.2`}</a>{` (2020-08-06)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` sectioning and content edits (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/635"
        }}>{`#635`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/bb2666b93d162fa8b05eae5389fd70f5b44799d9"
        }}>{`bb2666b`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@2.0.0...@twilio-paste/website@2.0.1"
      }}>{`2.0.1`}</a>{` (2020-08-06)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.40.6...@twilio-paste/website@2.0.0"
      }}>{`2.0.0`}</a>{` (2020-08-05)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` use display=block by default and rename 'iconColor' prop to 'color' (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/637"
        }}>{`#637`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/82beef487c514b2eab5c40b1ef1409842dd4ca82"
        }}>{`82beef4`}</a>{`)`}</li>
    </ul>
    <h3>{`BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`icons:`}</strong>{` This change renames a key prop and changes the display mode from inline-flex to block`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix: use new icon prop (iconColor -> color)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`test: update snapshots`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore(icons): update readme to mention correct display value`}</p>
      </li>
    </ul>
    <p>{`Co-authored-by: Rich Bachman `}<a parentName="p" {...{
        "href": "mailto:rb@richbachman.com"
      }}>{`rb@richbachman.com`}</a></p>
    <ul>
      <li parentName="ul">{`chore(website): update icon doc prop table`}</li>
    </ul>
    <p>{`Co-authored-by: Rich Bachman `}<a parentName="p" {...{
        "href": "mailto:rb@richbachman.com"
      }}>{`rb@richbachman.com`}</a></p>
    <p>{`Co-authored-by: Rich Bachman `}<a parentName="p" {...{
        "href": "mailto:rb@richbachman.com"
      }}>{`rb@richbachman.com`}</a></p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.40.5...@twilio-paste/website@1.40.6"
      }}>{`1.40.6`}</a>{` (2020-08-05)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.40.4...@twilio-paste/website@1.40.5"
      }}>{`1.40.5`}</a>{` (2020-08-04)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` fix icons yarn install command (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/630"
        }}>{`#630`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/01152e2b9607f9e6988e807c7a8540f40428514c"
        }}>{`01152e2`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.40.3...@twilio-paste/website@1.40.4"
      }}>{`1.40.4`}</a>{` (2020-08-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.40.2...@twilio-paste/website@1.40.3"
      }}>{`1.40.3`}</a>{` (2020-08-04)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` change tokens that were removed or changed (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/5c1635e34a7dd918a177b4a65e97b76274289cef"
        }}>{`5c1635e`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.40.1...@twilio-paste/website@1.40.2"
      }}>{`1.40.2`}</a>{` (2020-08-03)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`web:`}</strong>{` fix 404 links to github when logging in (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/124e77b3740d8edbb0cc42d48ab01be3362ea4a5"
        }}>{`124e77b`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.40.0...@twilio-paste/website@1.40.1"
      }}>{`1.40.1`}</a>{` (2020-07-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.39.4...@twilio-paste/website@1.40.0"
      }}>{`1.40.0`}</a>{` (2020-07-31)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`web:`}</strong>{` removed release version (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/f20cfbe2fc0452e25b885b26c576c0217ce1121c"
        }}>{`f20cfbe`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.39.3...@twilio-paste/website@1.39.4"
      }}>{`1.39.4`}</a>{` (2020-07-30)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.39.2...@twilio-paste/website@1.39.3"
      }}>{`1.39.3`}</a>{` (2020-07-30)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`web:`}</strong>{` eslint errors resolved (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/d14305c784dfea182d271f7547741e8d5da1eb75"
        }}>{`d14305c`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.39.1...@twilio-paste/website@1.39.2"
      }}>{`1.39.2`}</a>{` (2020-07-29)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` fix the global search from erroring on escape (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/f3a0dca3b7750228ef2ef332be14a78d087b9d37"
        }}>{`f3a0dca`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.39.0...@twilio-paste/website@1.39.1"
      }}>{`1.39.1`}</a>{` (2020-07-29)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.38.3...@twilio-paste/website@1.39.0"
      }}>{`1.39.0`}</a>{` (2020-07-29)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` updated theme switcher and alert text (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/613"
        }}>{`#613`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/d0a29d02ebea83e750169bd1071aa721d3fb00d3"
        }}>{`d0a29d0`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.38.2...@twilio-paste/website@1.38.3"
      }}>{`1.38.3`}</a>{` (2020-07-28)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.38.1...@twilio-paste/website@1.38.2"
      }}>{`1.38.2`}</a>{` (2020-07-28)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.38.0...@twilio-paste/website@1.38.1"
      }}>{`1.38.1`}</a>{` (2020-07-28)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.37.1...@twilio-paste/website@1.38.0"
      }}>{`1.38.0`}</a>{` (2020-07-28)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`menu:`}</strong>{` add MenuGroup (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/299ba82366c06a9655b4a9b39b4f56688d6d5004"
        }}>{`299ba82`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.37.0...@twilio-paste/website@1.37.1"
      }}>{`1.37.1`}</a>{` (2020-07-28)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.36.10...@twilio-paste/website@1.37.0"
      }}>{`1.37.0`}</a>{` (2020-07-27)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`web:`}</strong>{` add design guidelines back to nav (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/606"
        }}>{`#606`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/078974aefd741272085d4736da4003ab16a6b99a"
        }}>{`078974a`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.36.9...@twilio-paste/website@1.36.10"
      }}>{`1.36.10`}</a>{` (2020-07-24)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.36.8...@twilio-paste/website@1.36.9"
      }}>{`1.36.9`}</a>{` (2020-07-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.36.7...@twilio-paste/website@1.36.8"
      }}>{`1.36.8`}</a>{` (2020-07-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.36.6...@twilio-paste/website@1.36.7"
      }}>{`1.36.7`}</a>{` (2020-07-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.36.5...@twilio-paste/website@1.36.6"
      }}>{`1.36.6`}</a>{` (2020-07-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.36.4...@twilio-paste/website@1.36.5"
      }}>{`1.36.5`}</a>{` (2020-07-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.36.3...@twilio-paste/website@1.36.4"
      }}>{`1.36.4`}</a>{` (2020-07-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.36.2...@twilio-paste/website@1.36.3"
      }}>{`1.36.3`}</a>{` (2020-07-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.36.1...@twilio-paste/website@1.36.2"
      }}>{`1.36.2`}</a>{` (2020-07-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.36.0...@twilio-paste/website@1.36.1"
      }}>{`1.36.1`}</a>{` (2020-07-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.35.8...@twilio-paste/website@1.36.0"
      }}>{`1.36.0`}</a>{` (2020-07-13)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` add ChangelogRevealer shortcode (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/f556c419c46a8250f8d48c54ea7f4f45ac877b99"
        }}>{`f556c41`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.35.7...@twilio-paste/website@1.35.8"
      }}>{`1.35.8`}</a>{` (2020-07-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.35.6...@twilio-paste/website@1.35.7"
      }}>{`1.35.7`}</a>{` (2020-07-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.35.5...@twilio-paste/website@1.35.6"
      }}>{`1.35.6`}</a>{` (2020-07-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.35.4...@twilio-paste/website@1.35.5"
      }}>{`1.35.5`}</a>{` (2020-07-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.35.3...@twilio-paste/website@1.35.4"
      }}>{`1.35.4`}</a>{` (2020-07-06)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.35.2...@twilio-paste/website@1.35.3"
      }}>{`1.35.3`}</a>{` (2020-07-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.35.1...@twilio-paste/website@1.35.2"
      }}>{`1.35.2`}</a>{` (2020-07-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.35.0...@twilio-paste/website@1.35.1"
      }}>{`1.35.1`}</a>{` (2020-07-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.34.9...@twilio-paste/website@1.35.0"
      }}>{`1.35.0`}</a>{` (2020-07-01)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` edit, discussion, issue links on pages (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/541"
        }}>{`#541`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/28c56692fcdda410376a91dd7244f13d9fc697d1"
        }}>{`28c5669`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.34.8...@twilio-paste/website@1.34.9"
      }}>{`1.34.9`}</a>{` (2020-07-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.34.7...@twilio-paste/website@1.34.8"
      }}>{`1.34.8`}</a>{` (2020-06-30)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.34.6...@twilio-paste/website@1.34.7"
      }}>{`1.34.7`}</a>{` (2020-06-29)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.34.5...@twilio-paste/website@1.34.6"
      }}>{`1.34.6`}</a>{` (2020-06-27)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.34.4...@twilio-paste/website@1.34.5"
      }}>{`1.34.5`}</a>{` (2020-06-26)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.34.3...@twilio-paste/website@1.34.4"
      }}>{`1.34.4`}</a>{` (2020-06-26)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.34.2...@twilio-paste/website@1.34.3"
      }}>{`1.34.3`}</a>{` (2020-06-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.34.1...@twilio-paste/website@1.34.2"
      }}>{`1.34.2`}</a>{` (2020-06-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.34.0...@twilio-paste/website@1.34.1"
      }}>{`1.34.1`}</a>{` (2020-06-25)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` fixing callout's borderLeftWidth (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/e34bd53ee4cfcd1f71430724980133c86ae1424a"
        }}>{`e34bd53`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` themeSwitcher styles regression (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/ae98289a348b7931614f827940cb25eac7c1a470"
        }}>{`ae98289`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.33.8...@twilio-paste/website@1.34.0"
      }}>{`1.34.0`}</a>{` (2020-06-25)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` add tooltip-primitive doc page (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/40970cd00ae906a8fcafbaa96128bcea7a4670e6"
        }}>{`40970cd`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.33.7...@twilio-paste/website@1.33.8"
      }}>{`1.33.8`}</a>{` (2020-06-24)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`modal:`}</strong>{` add '`}{`_`}{`_`}{`console_patch' prop to fix bug in Console (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/522"
        }}>{`#522`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/7aaf81232b35de4beb1c5635aabf3bc63383c196"
        }}>{`7aaf812`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.33.6...@twilio-paste/website@1.33.7"
      }}>{`1.33.7`}</a>{` (2020-06-23)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.33.5...@twilio-paste/website@1.33.6"
      }}>{`1.33.6`}</a>{` (2020-06-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.33.4...@twilio-paste/website@1.33.5"
      }}>{`1.33.5`}</a>{` (2020-06-19)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` fix website scrolling (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/509"
        }}>{`#509`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/f5792cb6e21134e3e9fe16c9568732dad1c98206"
        }}>{`f5792cb`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.33.3...@twilio-paste/website@1.33.4"
      }}>{`1.33.4`}</a>{` (2020-06-18)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.33.2...@twilio-paste/website@1.33.3"
      }}>{`1.33.3`}</a>{` (2020-06-18)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.33.1...@twilio-paste/website@1.33.2"
      }}>{`1.33.2`}</a>{` (2020-06-16)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.33.0...@twilio-paste/website@1.33.1"
      }}>{`1.33.1`}</a>{` (2020-06-16)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.32.7...@twilio-paste/website@1.33.0"
      }}>{`1.33.0`}</a>{` (2020-06-13)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`add updates to content guidelines (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/503"
        }}>{`#503`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/53e0c91b9467349a471031273f765ca590b46bc0"
        }}>{`53e0c91`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.32.6...@twilio-paste/website@1.32.7"
      }}>{`1.32.7`}</a>{` (2020-06-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.32.5...@twilio-paste/website@1.32.6"
      }}>{`1.32.6`}</a>{` (2020-06-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.32.4...@twilio-paste/website@1.32.5"
      }}>{`1.32.5`}</a>{` (2020-06-10)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.32.3...@twilio-paste/website@1.32.4"
      }}>{`1.32.4`}</a>{` (2020-06-10)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.32.2...@twilio-paste/website@1.32.3"
      }}>{`1.32.3`}</a>{` (2020-06-10)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` adding resolution for vulnerable sub-dep (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/493"
        }}>{`#493`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/7d4dee510b88e9ad974c56435ea5cca5dcd57fcb"
        }}>{`7d4dee5`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` tabbing overflow bug and adding two landmark roles (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/488"
        }}>{`#488`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/08554921f759c7e7a919e91d1de4be3741f0969a"
        }}>{`0855492`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.32.1...@twilio-paste/website@1.32.2"
      }}>{`1.32.2`}</a>{` (2020-06-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.32.0...@twilio-paste/website@1.32.1"
      }}>{`1.32.1`}</a>{` (2020-06-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.31.3...@twilio-paste/website@1.32.0"
      }}>{`1.32.0`}</a>{` (2020-06-08)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`tokens docs:`}</strong>{` shorten description (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/479"
        }}>{`#479`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/450888cab9a6cd38fedc0a695149dcc62b0c0279"
        }}>{`450888c`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.31.2...@twilio-paste/website@1.31.3"
      }}>{`1.31.3`}</a>{` (2020-06-06)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` disabled algolia indexing temporarily (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/486"
        }}>{`#486`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/f72384dbc11a1e88f942675b0c07eacd9dcd525b"
        }}>{`f72384d`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.31.1...@twilio-paste/website@1.31.2"
      }}>{`1.31.2`}</a>{` (2020-06-05)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` setup responsive friendly structure (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/12d70a404b1bba0e328905bbf3377565bc0ab957"
        }}>{`12d70a4`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.31.0...@twilio-paste/website@1.31.1"
      }}>{`1.31.1`}</a>{` (2020-06-05)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`docs:`}</strong>{` homepage typo (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/485"
        }}>{`#485`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/2e94bb0423c27e2b328aa6831ec580df8d6e332f"
        }}>{`2e94bb0`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.30.5...@twilio-paste/website@1.31.0"
      }}>{`1.31.0`}</a>{` (2020-06-05)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` airtable roadmap (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/825ec55a68e16da7a5cbe39c30e862405f15de69"
        }}>{`825ec55`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.30.4...@twilio-paste/website@1.30.5"
      }}>{`1.30.5`}</a>{` (2020-06-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.30.3...@twilio-paste/website@1.30.4"
      }}>{`1.30.4`}</a>{` (2020-06-03)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.30.2...@twilio-paste/website@1.30.3"
      }}>{`1.30.3`}</a>{` (2020-06-03)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.30.1...@twilio-paste/website@1.30.2"
      }}>{`1.30.2`}</a>{` (2020-06-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.30.0...@twilio-paste/website@1.30.1"
      }}>{`1.30.1`}</a>{` (2020-06-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.29.2...@twilio-paste/website@1.30.0"
      }}>{`1.30.0`}</a>{` (2020-06-01)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`docs:`}</strong>{` add anti-racist quotes (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/470"
        }}>{`#470`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/12b8d8a13c918c7801273b9c698bd3eec4e668dd"
        }}>{`12b8d8a`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.29.1...@twilio-paste/website@1.29.2"
      }}>{`1.29.2`}</a>{` (2020-06-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.29.0...@twilio-paste/website@1.29.1"
      }}>{`1.29.1`}</a>{` (2020-06-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.28.3...@twilio-paste/website@1.29.0"
      }}>{`1.29.0`}</a>{` (2020-06-01)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` add algolia search (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/006731e9ac8d2c4d3002c50bddf05606dc0a6adf"
        }}>{`006731e`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.28.2...@twilio-paste/website@1.28.3"
      }}>{`1.28.3`}</a>{` (2020-06-01)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` add animation-library dependency (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/e02672caaddd0809d58cc9c48e4cd1762e497e6c"
        }}>{`e02672c`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.28.1...@twilio-paste/website@1.28.2"
      }}>{`1.28.2`}</a>{` (2020-05-29)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.28.0...@twilio-paste/website@1.28.1"
      }}>{`1.28.1`}</a>{` (2020-05-28)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.27.0...@twilio-paste/website@1.28.0"
      }}>{`1.28.0`}</a>{` (2020-05-27)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Modal docs:`}</strong>{` add guideline about using h4 in Modal Body (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/453"
        }}>{`#453`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/1430824b91d9ef6a1ad8eb51041816c5ab04e578"
        }}>{`1430824`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.26.0...@twilio-paste/website@1.27.0"
      }}>{`1.27.0`}</a>{` (2020-05-27)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` add menu to masthead (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/ce4723ac29391cdbaba8ee6abf75cd4fca5db587"
        }}>{`ce4723a`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.25.1...@twilio-paste/website@1.26.0"
      }}>{`1.26.0`}</a>{` (2020-05-27)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Paragraph docs:`}</strong>{` change Anatomy to show margin-bottom is modifiable (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/462"
        }}>{`#462`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/3b548409d5f4aba167b871140b8c040d1d164582"
        }}>{`3b54840`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.25.0...@twilio-paste/website@1.25.1"
      }}>{`1.25.1`}</a>{` (2020-05-26)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.24.5...@twilio-paste/website@1.25.0"
      }}>{`1.25.0`}</a>{` (2020-05-26)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` add disclosure primitive docs (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/a6b527b7c025fd018aea879b8e0b6b92a313c898"
        }}>{`a6b527b`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.24.4...@twilio-paste/website@1.24.5"
      }}>{`1.24.5`}</a>{` (2020-05-23)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.24.3...@twilio-paste/website@1.24.4"
      }}>{`1.24.4`}</a>{` (2020-05-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.24.2...@twilio-paste/website@1.24.3"
      }}>{`1.24.3`}</a>{` (2020-05-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.24.1...@twilio-paste/website@1.24.2"
      }}>{`1.24.2`}</a>{` (2020-05-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.24.0...@twilio-paste/website@1.24.1"
      }}>{`1.24.1`}</a>{` (2020-05-19)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.23.3...@twilio-paste/website@1.24.0"
      }}>{`1.24.0`}</a>{` (2020-05-14)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`checkbox docs:`}</strong>{` typo in "when to use" heading (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/439"
        }}>{`#439`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/07c0384cde1a7f4801edb6fc70362de72c14872f"
        }}>{`07c0384`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Modal primitive docs:`}</strong>{` add link to styled Modal (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/437"
        }}>{`#437`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/1e79fe10b8c17af03770b5dd4063887e0ce575ad"
        }}>{`1e79fe1`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.23.2...@twilio-paste/website@1.23.3"
      }}>{`1.23.3`}</a>{` (2020-05-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.23.1...@twilio-paste/website@1.23.2"
      }}>{`1.23.2`}</a>{` (2020-05-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.23.0...@twilio-paste/website@1.23.1"
      }}>{`1.23.1`}</a>{` (2020-05-12)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` add clarifying text around installing core (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/433"
        }}>{`#433`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/4f60c0f7fbb812063fef6fdb18de8690adac6370"
        }}>{`4f60c0f`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.22.2...@twilio-paste/website@1.23.0"
      }}>{`1.23.0`}</a>{` (2020-05-11)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` add stack doc page (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/8dab570468083bf89d9f2f9b17ad60143bd0d520"
        }}>{`8dab570`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.22.1...@twilio-paste/website@1.22.2"
      }}>{`1.22.2`}</a>{` (2020-05-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.22.0...@twilio-paste/website@1.22.1"
      }}>{`1.22.1`}</a>{` (2020-05-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.21.0...@twilio-paste/website@1.22.0"
      }}>{`1.22.0`}</a>{` (2020-05-07)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` changing the website icon to new Paste icon (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/431"
        }}>{`#431`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/7b61fe69436218d1affb7f126e7dc2ca1f18da2e"
        }}>{`7b61fe6`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.20.7...@twilio-paste/website@1.21.0"
      }}>{`1.21.0`}</a>{` (2020-05-07)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` friendly token values (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/427"
        }}>{`#427`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/43104e6f21e928299c83b7f8d1f3175396aea791"
        }}>{`43104e6`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.20.6...@twilio-paste/website@1.20.7"
      }}>{`1.20.7`}</a>{` (2020-05-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.20.5...@twilio-paste/website@1.20.6"
      }}>{`1.20.6`}</a>{` (2020-05-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.20.4...@twilio-paste/website@1.20.5"
      }}>{`1.20.5`}</a>{` (2020-05-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.20.3...@twilio-paste/website@1.20.4"
      }}>{`1.20.4`}</a>{` (2020-05-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.20.2...@twilio-paste/website@1.20.3"
      }}>{`1.20.3`}</a>{` (2020-05-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.20.1...@twilio-paste/website@1.20.2"
      }}>{`1.20.2`}</a>{` (2020-04-30)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`paragraph docs:`}</strong>{` margin-bottom in Anatomy (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/413"
        }}>{`#413`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/ee125e7fb4ba092fd9e2beecffbf4c89162ab3dd"
        }}>{`ee125e7`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.20.0...@twilio-paste/website@1.20.1"
      }}>{`1.20.1`}</a>{` (2020-04-29)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` update button prop table (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/7718d435b975b5975fd5e63c353cd1c573a4bde7"
        }}>{`7718d43`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.19.2...@twilio-paste/website@1.20.0"
      }}>{`1.20.0`}</a>{` (2020-04-28)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` add reset button example to button doc page (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/407"
        }}>{`#407`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/ba6d00c1cd0d44fec81fba94d60016cd12271c17"
        }}>{`ba6d00c`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.19.1...@twilio-paste/website@1.19.2"
      }}>{`1.19.2`}</a>{` (2020-04-28)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.19.0...@twilio-paste/website@1.19.1"
      }}>{`1.19.1`}</a>{` (2020-04-26)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` typo in live radio and checkbox examples (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/408"
        }}>{`#408`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/bbbc9293b068723e374676a4a4a76077ff353b70"
        }}>{`bbbc929`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.18.0...@twilio-paste/website@1.19.0"
      }}>{`1.19.0`}</a>{` (2020-04-25)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` correctly nest list item in side nav (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/8c9deaf605889d0903c91a5a91d5300dda42bbf8"
        }}>{`8c9deaf`}</a>{`)`}</li>
    </ul>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` add radio and checkbox docs (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/67700cbd4f92478fb452df01a2de6cd9cc2f8ede"
        }}>{`67700cb`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.17.9...@twilio-paste/website@1.18.0"
      }}>{`1.18.0`}</a>{` (2020-04-24)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`alert docs:`}</strong>{` update color tokens in Anatomy (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/402"
        }}>{`#402`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/33d0129bb955ed65c4739e01d04b474b00f061c3"
        }}>{`33d0129`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.17.8...@twilio-paste/website@1.17.9"
      }}>{`1.17.9`}</a>{` (2020-04-24)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` add missing variants and update heading props table (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/54c00131ca0bf2742993e47a787ca1ddd85e13fd"
        }}>{`54c0013`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.17.7...@twilio-paste/website@1.17.8"
      }}>{`1.17.8`}</a>{` (2020-04-24)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.17.6...@twilio-paste/website@1.17.7"
      }}>{`1.17.7`}</a>{` (2020-04-23)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` change codeblock background to white for visibility (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/def6be44f5b49246312652dd4fbd22fdae5698ef"
        }}>{`def6be4`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.17.5...@twilio-paste/website@1.17.6"
      }}>{`1.17.6`}</a>{` (2020-04-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.17.4...@twilio-paste/website@1.17.5"
      }}>{`1.17.5`}</a>{` (2020-04-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.17.3...@twilio-paste/website@1.17.4"
      }}>{`1.17.4`}</a>{` (2020-04-17)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`remove mention of old Sketch icon templates (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/397"
        }}>{`#397`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/315f2ec2c73b47ae5883ef18529e0bf0e0469a92"
        }}>{`315f2ec`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.17.2...@twilio-paste/website@1.17.3"
      }}>{`1.17.3`}</a>{` (2020-04-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.17.1...@twilio-paste/website@1.17.2"
      }}>{`1.17.2`}</a>{` (2020-04-16)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`docs:`}</strong>{` remove todo callout in icons (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/378"
        }}>{`#378`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/12e86553d7f2e29ecebbfe7903113e3e92a01e6b"
        }}>{`12e8655`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.17.0...@twilio-paste/website@1.17.1"
      }}>{`1.17.1`}</a>{` (2020-04-16)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.16.5...@twilio-paste/website@1.17.0"
      }}>{`1.17.0`}</a>{` (2020-04-15)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` replace styled hr with separator component (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/e735e61a7b2fb83f1e3aa547697f33d6477ed299"
        }}>{`e735e61`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.16.4...@twilio-paste/website@1.16.5"
      }}>{`1.16.5`}</a>{` (2020-04-15)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`modal docs:`}</strong>{` swap alias names in Anatomy with token (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/391"
        }}>{`#391`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/a1925850a7e08d4ae6c722a8b1c379b40cc0900c"
        }}>{`a192585`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.16.3...@twilio-paste/website@1.16.4"
      }}>{`1.16.4`}</a>{` (2020-04-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.16.2...@twilio-paste/website@1.16.3"
      }}>{`1.16.3`}</a>{` (2020-04-13)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`docs:`}</strong>{` Change instances of "helper" to "help" on form components (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/384"
        }}>{`#384`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/e9b7e43b7bacc75220faf99ad110fa21b2e0a76c"
        }}>{`e9b7e43`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.16.1...@twilio-paste/website@1.16.2"
      }}>{`1.16.2`}</a>{` (2020-04-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.16.0...@twilio-paste/website@1.16.1"
      }}>{`1.16.1`}</a>{` (2020-04-13)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` add overflow-y to do/dont Box (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/376"
        }}>{`#376`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/4684e429ed6f1fba2ff49fbffa4c4d95b00a31e5"
        }}>{`4684e42`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.15.18...@twilio-paste/website@1.16.0"
      }}>{`1.16.0`}</a>{` (2020-04-10)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`remove "close icon" sizing note (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/377"
        }}>{`#377`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/01ddbc6382f9b55b639161b4d295241ed22f8fe7"
        }}>{`01ddbc6`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.15.17...@twilio-paste/website@1.15.18"
      }}>{`1.15.18`}</a>{` (2020-04-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.15.16...@twilio-paste/website@1.15.17"
      }}>{`1.15.17`}</a>{` (2020-04-09)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` include more details around loading the Whitney font (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/382"
        }}>{`#382`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/41d5005f4c9874c11f72cb4d9be29ccf812dbf16"
        }}>{`41d5005`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.15.15...@twilio-paste/website@1.15.16"
      }}>{`1.15.16`}</a>{` (2020-04-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.15.14...@twilio-paste/website@1.15.15"
      }}>{`1.15.15`}</a>{` (2020-04-09)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` typo fixes (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/370"
        }}>{`#370`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/23d703fa80ac7c5ae0bb15e9e95bf520b78dca12"
        }}>{`23d703f`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.15.13...@twilio-paste/website@1.15.14"
      }}>{`1.15.14`}</a>{` (2020-04-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.15.12...@twilio-paste/website@1.15.13"
      }}>{`1.15.13`}</a>{` (2020-04-08)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` minor text changes for textarea doc (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/374"
        }}>{`#374`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/54fc6f038dba48a5ca747545db310721ac3d7719"
        }}>{`54fc6f0`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.15.11...@twilio-paste/website@1.15.12"
      }}>{`1.15.12`}</a>{` (2020-04-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.15.10...@twilio-paste/website@1.15.11"
      }}>{`1.15.11`}</a>{` (2020-04-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.15.9...@twilio-paste/website@1.15.10"
      }}>{`1.15.10`}</a>{` (2020-04-07)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` change textColor references to color (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/eca7434b2c3621f4565e0423d06283433f235a3d"
        }}>{`eca7434`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.15.8...@twilio-paste/website@1.15.9"
      }}>{`1.15.9`}</a>{` (2020-04-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.15.7...@twilio-paste/website@1.15.8"
      }}>{`1.15.8`}</a>{` (2020-04-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.15.6...@twilio-paste/website@1.15.7"
      }}>{`1.15.7`}</a>{` (2020-04-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.15.5...@twilio-paste/website@1.15.6"
      }}>{`1.15.6`}</a>{` (2020-04-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.15.4...@twilio-paste/website@1.15.5"
      }}>{`1.15.5`}</a>{` (2020-04-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.15.3...@twilio-paste/website@1.15.4"
      }}>{`1.15.4`}</a>{` (2020-03-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.15.2...@twilio-paste/website@1.15.3"
      }}>{`1.15.3`}</a>{` (2020-03-30)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.15.1...@twilio-paste/website@1.15.2"
      }}>{`1.15.2`}</a>{` (2020-03-28)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.15.0...@twilio-paste/website@1.15.1"
      }}>{`1.15.1`}</a>{` (2020-03-27)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.14.1...@twilio-paste/website@1.15.0"
      }}>{`1.15.0`}</a>{` (2020-03-27)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` creating content guidelines section (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/344"
        }}>{`#344`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/6c2e98cf51011e9b116e6e90cfde13ace4efda62"
        }}>{`6c2e98c`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.14.0...@twilio-paste/website@1.14.1"
      }}>{`1.14.1`}</a>{` (2020-03-25)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` change FormHelperText in input doc (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/a72dacb965912fb0a25fadda2ff43efdd7fffcf1"
        }}>{`a72dacb`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.13.16...@twilio-paste/website@1.14.0"
      }}>{`1.14.0`}</a>{` (2020-03-25)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` add input docs (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/354"
        }}>{`#354`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/cb757bf9fc0ce1ab0ca030ac84f8206e889cff65"
        }}>{`cb757bf`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.13.15...@twilio-paste/website@1.13.16"
      }}>{`1.13.16`}</a>{` (2020-03-24)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` switch from theme-tokens to theme package dep (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/f87cfd0e720e1e91a5790ec95c1211ff8efd269b"
        }}>{`f87cfd0`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.13.14...@twilio-paste/website@1.13.15"
      }}>{`1.13.15`}</a>{` (2020-03-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.13.13...@twilio-paste/website@1.13.14"
      }}>{`1.13.14`}</a>{` (2020-03-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.13.12...@twilio-paste/website@1.13.13"
      }}>{`1.13.13`}</a>{` (2020-03-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.13.11...@twilio-paste/website@1.13.12"
      }}>{`1.13.12`}</a>{` (2020-03-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.13.10...@twilio-paste/website@1.13.11"
      }}>{`1.13.11`}</a>{` (2020-03-17)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` fix regression in sidebar nav (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/349"
        }}>{`#349`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/02c81611917bc41eb63e97b960c7d60f7aacfbac"
        }}>{`02c8161`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.13.9...@twilio-paste/website@1.13.10"
      }}>{`1.13.10`}</a>{` (2020-03-17)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` amend how we type some styled components (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/6f324fa97b4dd66391ad9febeb1ca2ebf8676430"
        }}>{`6f324fa`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.13.8...@twilio-paste/website@1.13.9"
      }}>{`1.13.9`}</a>{` (2020-03-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.13.7...@twilio-paste/website@1.13.8"
      }}>{`1.13.8`}</a>{` (2020-03-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.13.6...@twilio-paste/website@1.13.7"
      }}>{`1.13.7`}</a>{` (2020-03-06)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`add missing transitive peerDep on '@styled-system/css' (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/fdbb81370cda71037348742a680299ce481eabfd"
        }}>{`fdbb813`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.13.5...@twilio-paste/website@1.13.6"
      }}>{`1.13.6`}</a>{` (2020-03-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.13.4...@twilio-paste/website@1.13.5"
      }}>{`1.13.5`}</a>{` (2020-03-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.13.3...@twilio-paste/website@1.13.4"
      }}>{`1.13.4`}</a>{` (2020-03-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.13.2...@twilio-paste/website@1.13.3"
      }}>{`1.13.3`}</a>{` (2020-02-28)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.13.1...@twilio-paste/website@1.13.2"
      }}>{`1.13.2`}</a>{` (2020-02-26)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.13.0...@twilio-paste/website@1.13.1"
      }}>{`1.13.1`}</a>{` (2020-02-26)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`package dependencies and deprecation warnings (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/334"
        }}>{`#334`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/0e88338511e6835a79eb0a9cea8d5b3a1cdf0a88"
        }}>{`0e88338`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.12.3...@twilio-paste/website@1.13.0"
      }}>{`1.13.0`}</a>{` (2020-02-25)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` new modal dialog primitive docs (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/327"
        }}>{`#327`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/9ec8308664ffdd753e537d0a92a634973e9debe8"
        }}>{`9ec8308`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.12.2...@twilio-paste/website@1.12.3"
      }}>{`1.12.3`}</a>{` (2020-02-21)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.12.1...@twilio-paste/website@1.12.2"
      }}>{`1.12.2`}</a>{` (2020-02-21)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` filter more headings from ToC (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/326"
        }}>{`#326`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/a371588f9905bd8613f4a223811c433c55bef925"
        }}>{`a371588`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` icons self-service path (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/324"
        }}>{`#324`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/48fccf87953919926e9c673348312bdb517516c7"
        }}>{`48fccf8`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.12.0...@twilio-paste/website@1.12.1"
      }}>{`1.12.1`}</a>{` (2020-02-21)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.11.2...@twilio-paste/website@1.12.0"
      }}>{`1.12.0`}</a>{` (2020-02-21)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` add browser support information (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/319"
        }}>{`#319`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/84cae22934d265bd2b1a5141f09dfc9d84591963"
        }}>{`84cae22`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.11.1...@twilio-paste/website@1.11.2"
      }}>{`1.11.2`}</a>{` (2020-02-21)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` update engineering guide (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/322"
        }}>{`#322`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/5297276cf980cab79d9b9fa9e56449263e33811b"
        }}>{`5297276`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.11.0...@twilio-paste/website@1.11.1"
      }}>{`1.11.1`}</a>{` (2020-02-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.10.18...@twilio-paste/website@1.11.0"
      }}>{`1.11.0`}</a>{` (2020-02-20)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` text update on icon design instructions (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/321"
        }}>{`#321`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/1c8a2047c12dfa10235471dc9d974832ba4ee275"
        }}>{`1c8a204`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.10.17...@twilio-paste/website@1.10.18"
      }}>{`1.10.18`}</a>{` (2020-02-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.10.16...@twilio-paste/website@1.10.17"
      }}>{`1.10.17`}</a>{` (2020-02-19)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.10.15...@twilio-paste/website@1.10.16"
      }}>{`1.10.16`}</a>{` (2020-02-19)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.10.14...@twilio-paste/website@1.10.15"
      }}>{`1.10.15`}</a>{` (2020-02-19)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.10.13...@twilio-paste/website@1.10.14"
      }}>{`1.10.14`}</a>{` (2020-02-19)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.10.12...@twilio-paste/website@1.10.13"
      }}>{`1.10.13`}</a>{` (2020-02-18)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` adjust CalloutText spacing (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/d4079f7966b8fbd1c2d69ad176fef772cb427e93"
        }}>{`d4079f7`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` tidying CalloutText usage (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/348f00dd434e2f35ce94e5fe5dc977c2f67da6a8"
        }}>{`348f00d`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.10.11...@twilio-paste/website@1.10.12"
      }}>{`1.10.12`}</a>{` (2020-02-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.10.10...@twilio-paste/website@1.10.11"
      }}>{`1.10.11`}</a>{` (2020-02-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.10.9...@twilio-paste/website@1.10.10"
      }}>{`1.10.10`}</a>{` (2020-02-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.10.8...@twilio-paste/website@1.10.9"
      }}>{`1.10.9`}</a>{` (2020-02-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.10.7...@twilio-paste/website@1.10.8"
      }}>{`1.10.8`}</a>{` (2020-02-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.10.6...@twilio-paste/website@1.10.7"
      }}>{`1.10.7`}</a>{` (2020-02-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.10.5...@twilio-paste/website@1.10.6"
      }}>{`1.10.6`}</a>{` (2020-02-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.10.4...@twilio-paste/website@1.10.5"
      }}>{`1.10.5`}</a>{` (2020-02-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.10.3...@twilio-paste/website@1.10.4"
      }}>{`1.10.4`}</a>{` (2020-02-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.10.2...@twilio-paste/website@1.10.3"
      }}>{`1.10.3`}</a>{` (2020-02-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.10.1...@twilio-paste/website@1.10.2"
      }}>{`1.10.2`}</a>{` (2020-02-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.10.0...@twilio-paste/website@1.10.1"
      }}>{`1.10.1`}</a>{` (2020-02-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.9.1...@twilio-paste/website@1.10.0"
      }}>{`1.10.0`}</a>{` (2020-02-10)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` create website blockquote (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/299"
        }}>{`#299`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/ebee4868ae9aa597175fcde3870062f21ff608db"
        }}>{`ebee486`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.9.0...@twilio-paste/website@1.9.1"
      }}>{`1.9.1`}</a>{` (2020-02-10)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.8.9...@twilio-paste/website@1.9.0"
      }}>{`1.9.0`}</a>{` (2020-02-08)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` create the screen-reader-only documentation page (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/291"
        }}>{`#291`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/a8bc513e731c416b0b00041913e7e07d98eabf8e"
        }}>{`a8bc513`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.8.8...@twilio-paste/website@1.8.9"
      }}>{`1.8.9`}</a>{` (2020-02-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.8.7...@twilio-paste/website@1.8.8"
      }}>{`1.8.8`}</a>{` (2020-02-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.8.6...@twilio-paste/website@1.8.7"
      }}>{`1.8.7`}</a>{` (2020-02-03)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.8.5...@twilio-paste/website@1.8.6"
      }}>{`1.8.6`}</a>{` (2020-01-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.8.4...@twilio-paste/website@1.8.5"
      }}>{`1.8.5`}</a>{` (2020-01-30)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.8.3...@twilio-paste/website@1.8.4"
      }}>{`1.8.4`}</a>{` (2020-01-29)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.8.2...@twilio-paste/website@1.8.3"
      }}>{`1.8.3`}</a>{` (2020-01-29)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.8.1...@twilio-paste/website@1.8.2"
      }}>{`1.8.2`}</a>{` (2020-01-27)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.8.0...@twilio-paste/website@1.8.1"
      }}>{`1.8.1`}</a>{` (2020-01-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.7.0...@twilio-paste/website@1.8.0"
      }}>{`1.8.0`}</a>{` (2020-01-24)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`"SHIFT" to "OPTION" in "Formatting an icon" (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/274"
        }}>{`#274`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/99eb18869947ddbdaa148824661949c2f0b6a626"
        }}>{`99eb188`}</a>{`)`}</li>
    </ul>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`text formatting and copy updates to alert docs (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/266"
        }}>{`#266`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/6221c89f52e32fca292df52c615e8c3bc8858e79"
        }}>{`6221c89`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.6.0...@twilio-paste/website@1.7.0"
      }}>{`1.7.0`}</a>{` (2020-01-17)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` add alert documentation (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/ca70ded3a6f2491e09cec864b58b96c312b38e0e"
        }}>{`ca70ded`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.5.2...@twilio-paste/website@1.6.0"
      }}>{`1.6.0`}</a>{` (2019-12-20)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`card:`}</strong>{` updated card design and documentation (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/230"
        }}>{`#230`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/e0e2ca79569f2b2aca1b6ba1b39fc48358381291"
        }}>{`e0e2ca7`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` add the paste theme to the theme switcher (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/4d4919f595b19b13cad2792a2eae9656155f6611"
        }}>{`4d4919f`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.5.1...@twilio-paste/website@1.5.2"
      }}>{`1.5.2`}</a>{` (2019-12-19)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` mobile experience of website (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/226"
        }}>{`#226`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/203159e33c859c5eb45638707c392c7e3831b0b9"
        }}>{`203159e`}</a>{`)`}</li>
      <li parentName="ul">{`add height to styled code to prevent overflow (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/231"
        }}>{`#231`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/f27094574ec09776ed896584ef6fb24d1c8233d5"
        }}>{`f270945`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` swap types package for style-props and minor copy updates (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/bacac705f7588be15acc63707e8a7a53c6cd42d5"
        }}>{`bacac70`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.5.0...@twilio-paste/website@1.5.1"
      }}>{`1.5.1`}</a>{` (2019-12-05)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` display text tokens on the correct inverse bg color (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/220"
        }}>{`#220`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/3aaa0f886fbf2b38d9de5b3c02fbcc231c56b7e4"
        }}>{`3aaa0f8`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` push toast component to release 0.6 (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/207"
        }}>{`#207`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/c510588ea84d1aeba8cd216667a319cfccd93742"
        }}>{`c510588`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.4.1...@twilio-paste/website@1.5.0"
      }}>{`1.5.0`}</a>{` (2019-11-27)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`update roadmap page through release 0.7 (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/201"
        }}>{`#201`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/194b360afa31d3d429fab2e0f9b302af6fc321cb"
        }}>{`194b360`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.4.0...@twilio-paste/website@1.4.1"
      }}>{`1.4.1`}</a>{` (2019-11-21)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`change heading variants (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/196"
        }}>{`#196`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/061cc3ecce7bc7ee5b8c8d2e95ddb8e0cd744e64"
        }}>{`061cc3e`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.3.0...@twilio-paste/website@1.4.0"
      }}>{`1.4.0`}</a>{` (2019-11-20)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` make decorative required + icon docs (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/165"
        }}>{`#165`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/15ccbc2e0f259d60af83bf8503c8ee18a1191cb8"
        }}>{`15ccbc2`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.2.1...@twilio-paste/website@1.3.0"
      }}>{`1.3.0`}</a>{` (2019-11-18)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`card code example spacing and formatting (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/187"
        }}>{`#187`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/57a46f41acb8987243917cd31fdb1449c69e89e7"
        }}>{`57a46f4`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`design-tokens:`}</strong>{` update some blues, reds, and focus shadow (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/185"
        }}>{`#185`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/98d70cc046db0284e151fab7f427ee16b8874c4a"
        }}>{`98d70cc`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` remove vague anchor do dont (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/177"
        }}>{`#177`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/8530c5679b48da46ddc82a9a7154bca78cacc30a"
        }}>{`8530c56`}</a>{`)`}</li>
    </ul>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`card:`}</strong>{` card component (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/145"
        }}>{`#145`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/9049c5f8953b8ebee68596fedbcd58f6054ef166"
        }}>{`9049c5f`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.2.0...@twilio-paste/website@1.2.1"
      }}>{`1.2.1`}</a>{` (2019-11-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/website`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.1.0...@twilio-paste/website@1.2.0"
      }}>{`1.2.0`}</a>{` (2019-11-11)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` update button docs with loading and icon states (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/167"
        }}>{`#167`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/73808897ef181da087a5a80a17a80a74409a6c3c"
        }}>{`7380889`}</a>{`)`}</li>
    </ul>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`typography:`}</strong>{` heading component (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/149"
        }}>{`#149`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/4e033e6f3e58566107271b18057532e768420827"
        }}>{`4e033e6`}</a>{`)`}</li>
      <li parentName="ul">{`enable theme switching on the docsite (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/124"
        }}>{`#124`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/df797e57ff1268367053d717f3b3c2ca48a0aa4f"
        }}>{`df797e5`}</a>{`)`}</li>
      <li parentName="ul">{`update getting started (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/162"
        }}>{`#162`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/b589641d95c98b9cdc244836c2c5736393876a95"
        }}>{`b589641`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` added callout with help for component and utility lists (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/168"
        }}>{`#168`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/b4be1d74d369a7312f28e9fd565abab0710676c8"
        }}>{`b4be1d7`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@1.0.0...@twilio-paste/website@1.1.0"
      }}>{`1.1.0`}</a>{` (2019-10-31)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`change the version number the website displays (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/157"
        }}>{`#157`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/553be6d8a0412e9b0b2f73e017b05c83c21b7361"
        }}>{`553be6d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`anchor:`}</strong>{` change hover styles (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/155"
        }}>{`#155`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/5172b0bb761e6c8334aac7fd94e615cac705d242"
        }}>{`5172b0b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`anchor:`}</strong>{` export types and add tests (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/153"
        }}>{`#153`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/4086ee28da829820ca8f791e4bddf2768bd8b2f9"
        }}>{`4086ee2`}</a>{`)`}</li>
    </ul>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`update types package to styled-system v5 format (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/154"
        }}>{`#154`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/117c41e463b11440582e740c28cc067c06d16e67"
        }}>{`117c41e`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@0.2.0...@twilio-paste/website@1.0.0"
      }}>{`1.0.0`}</a>{` (2019-10-29)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` add wrapper to engineering guidelines (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/112"
        }}>{`#112`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/9505f9587132b276579cb48189d7045f5b2c60e0"
        }}>{`9505f95`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` adding spaces around font tester file link (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/108"
        }}>{`#108`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/505700a4aea74b34f2f459f79568f2d5082bb8fa"
        }}>{`505700a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` automatically update website version from package (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/99"
        }}>{`#99`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/9d181071c7ff9f7a2670d2170349ffa9f749d031"
        }}>{`9d18107`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` change breadcrumb slash from aside to span (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/128"
        }}>{`#128`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/e637eb4ceb452c883719c3306478824abd226a93"
        }}>{`e637eb4`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` change home buttons back to links (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/146"
        }}>{`#146`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/45b3187ec2349adb6fcb1da3a5b83d8928fce263"
        }}>{`45b3187`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` correct nesting for principles sidenav link (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/120"
        }}>{`#120`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/0d7644a3d9d3eb44e6b86e2d380cccbab9fcafdb"
        }}>{`0d7644a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` correct the broken links on token pages (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/107"
        }}>{`#107`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/65f613c5bca205e67ae2330ad57eb42369846d00"
        }}>{`65f613c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` updates border color to use color box (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/144"
        }}>{`#144`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/c44ceb7b405a9c63bdcd95390e937086572bbf3f"
        }}>{`c44ceb7`}</a>{`)`}</li>
    </ul>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`add info from brown bag questions (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/105"
        }}>{`#105`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/f67fb79735b5cc068403bd90db29c85067dc5aa9"
        }}>{`f67fb79`}</a>{`)`}</li>
      <li parentName="ul">{`design token and theme package documentation (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/103"
        }}>{`#103`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/30601132ccdec8f0fee79e0f293c80d0c0cff335"
        }}>{`3060113`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`box:`}</strong>{` polish and fix (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/125"
        }}>{`#125`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/d151badadfa4db8ade1425df9336e0155ce09619"
        }}>{`d151bad`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` add slugs to mdx GraphQL (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/110"
        }}>{`#110`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/07c8d6a1ced60c34cb36003e565d7b750917b585"
        }}>{`07c8d6a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` aspect-ratio docs page (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/104"
        }}>{`#104`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/7a78689891468180929bb3cea45207ac09699f81"
        }}>{`7a78689`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` roadmap page (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/100"
        }}>{`#100`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/24c067a3983da7d27a9e8b339b2e6e4991f3f6db"
        }}>{`24c067a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` SiteLink Component, replace Link with SiteLink (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/135"
        }}>{`#135`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/1295a33862b3ad283c4af793e0566f4b0fcba4b5"
        }}>{`1295a33`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` Table of Contents Component (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/127"
        }}>{`#127`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/5d3959534bb66d422dbd1b3e6374a81fda82d4b5"
        }}>{`5d39595`}</a>{`)`}</li>
      <li parentName="ul">{`finalise the Text component (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/151"
        }}>{`#151`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/b51c272cf420380f73c31c3474ebdf23cb7cad3a"
        }}>{`b51c272`}</a>{`)`}</li>
      <li parentName="ul">{`upgrading to styled-system v5 (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/18"
        }}>{`#18`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/2b3ebd8b2f701a0c6e8b75ab6978ba936814f455"
        }}>{`2b3ebd8`}</a>{`)`}</li>
    </ul>
    <h3>{`BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`box:`}</strong>{` many Box API changes`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(theme-tokens): add sizes key for styled-system responsiveness`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(absolute): update to newer Box`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(types): allow for responsive values via array`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(box): update story and light tweaks to OverflowProps`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore: update yarn.lock file`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(types): many more types fixes`}</p>
      </li>
    </ul>
    <p>{`refactor: moved ValueOf into types package`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`fix(box): eslint error in story`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(box): using the correct BorderProps definition`}</p>
      </li>
    </ul>
    <p>{`fix: we shouldn't allow users to pass the `}<inlineCode parentName="p">{`border`}</inlineCode>{` shorthand, it circumvents tokens`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`fix: lint errors`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(website): update box docs`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(storybook): use theme breakpoints`}</p>
      </li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/website@0.1.0...@twilio-paste/website@0.2.0"
      }}>{`0.2.0`}</a>{` (2019-09-16)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`make CI/CI great again (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/89"
        }}>{`#89`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/6195dac"
        }}>{`6195dac`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` component header and overview with utilities (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/64"
        }}>{`#64`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/07bf7aa"
        }}>{`07bf7aa`}</a>{`)`}</li>
      <li parentName="ul">{`typography polish and Token page (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/43"
        }}>{`#43`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/e27f113"
        }}>{`e27f113`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`button:`}</strong>{` adding some polish (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/67"
        }}>{`#67`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/2644657"
        }}>{`2644657`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`tokens:`}</strong>{` correct the box shadow tokens category and type (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/95"
        }}>{`#95`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/fe0f946"
        }}>{`fe0f946`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` add button docs (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/51"
        }}>{`#51`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/7ffc6d3"
        }}>{`7ffc6d3`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` Anchor doc (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/37"
        }}>{`#37`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/b3deb11"
        }}>{`b3deb11`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` change intro copy (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/55"
        }}>{`#55`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/67b3317"
        }}>{`67b3317`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` changed p to Text (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/63"
        }}>{`#63`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/05f38ae"
        }}>{`05f38ae`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` composable do-dont, add anchor examples (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/81"
        }}>{`#81`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/a799f1b"
        }}>{`a799f1b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` empty state / 404, component overview tweaks (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/46"
        }}>{`#46`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/d9bf17f"
        }}>{`d9bf17f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` fix missing link in anchors page `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/60"
        }}>{`#60`}</a>{` (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/1972aa0"
        }}>{`1972aa0`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` import order lint (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/2a8ec35"
        }}>{`2a8ec35`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` tody up the button and anchor docs (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/84"
        }}>{`#84`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/300a4bf"
        }}>{`300a4bf`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` update favicon, add manifest plugin (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/90"
        }}>{`#90`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/b2ee34b"
        }}>{`b2ee34b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` update flex docs links (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/65"
        }}>{`#65`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/d901ba1"
        }}>{`d901ba1`}</a>{`)`}</li>
    </ul>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`tokens:`}</strong>{` Add box-shadow and line-height tokens (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/35"
        }}>{`#35`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/706643b"
        }}>{`706643b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` add theme switcher and theme switching (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/82"
        }}>{`#82`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/a3b400d"
        }}>{`a3b400d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` gatsby-image and image plugins (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/62"
        }}>{`#62`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/c3e48fd"
        }}>{`c3e48fd`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` hyperlink doc site headers (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/88"
        }}>{`#88`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/ed7d2da"
        }}>{`ed7d2da`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` site header (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/33"
        }}>{`#33`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/cb044c4"
        }}>{`cb044c4`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` website changelogs (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/70"
        }}>{`#70`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/f56cf51"
        }}>{`f56cf51`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` website footer (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/38"
        }}>{`#38`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/22cae4c"
        }}>{`22cae4c`}</a>{`)`}</li>
    </ul>
    <h1>{`0.1.0 (2019-08-15)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` correct DSYS 1021 merge (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/23"
        }}>{`#23`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/ee3f1cd"
        }}>{`ee3f1cd`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` update ga tracking id (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/29"
        }}>{`#29`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/3fbd4d0"
        }}>{`3fbd4d0`}</a>{`)`}</li>
      <li parentName="ul">{`change `}<a parentName="li" {...{
          "href": "https://github.com/paste"
        }}>{`@paste`}</a>{` scope to `}<a parentName="li" {...{
          "href": "https://github.com/twilio-paste"
        }}>{`@twilio-paste`}</a>{` (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/2"
        }}>{`#2`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/1d8d2ff"
        }}>{`1d8d2ff`}</a>{`)`}</li>
      <li parentName="ul">{`run eslint across the right files and fix any issues that arise. (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/21"
        }}>{`#21`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/2fcc872"
        }}>{`2fcc872`}</a>{`)`}</li>
      <li parentName="ul">{`Type-checking fixes (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/12"
        }}>{`#12`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/be02450"
        }}>{`be02450`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` Fix Header directory name casing to header (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/7"
        }}>{`#7`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/79571ae"
        }}>{`79571ae`}</a>{`)`}</li>
    </ul>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` do and don't component (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/42"
        }}>{`#42`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/0d996f6"
        }}>{`0d996f6`}</a>{`)`}</li>
      <li parentName="ul">{`404 page and empty states (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/41"
        }}>{`#41`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/ec3614f"
        }}>{`ec3614f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` `}{`[DSYS-1046]`}{` website structure and navigation (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/306586e"
        }}>{`306586e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` add mdx livepreview component and styles (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/30"
        }}>{`#30`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/ab9d6ba"
        }}>{`ab9d6ba`}</a>{`)`}</li>
      <li parentName="ul">{`ComponentHeader component, component overview fixes, more (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/20"
        }}>{`#20`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/875e124"
        }}>{`875e124`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` Add text color token accessibility rating to the token page (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/28"
        }}>{`#28`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/74f12d1"
        }}>{`74f12d1`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` Add the tokens page (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/19"
        }}>{`#19`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/878d3c5"
        }}>{`878d3c5`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` Callout component (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/25"
        }}>{`#25`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/e4a3d00"
        }}>{`e4a3d00`}</a>{`)`}</li>
      <li parentName="ul">{`new table component (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/16"
        }}>{`#16`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/b4ffae4"
        }}>{`b4ffae4`}</a>{`)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      